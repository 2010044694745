import React, { Fragment, useRef, useState } from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import NickHeroImage from "../images/nick.png";
import { SEO } from "../components/SEO";
import Layout from "../components/Layout";
import ContributingFor from "../components/ContributingFor";
import { graphql, Script } from "gatsby";
import Footer from "../components/Footer";

const IndexPage = ({ data: { sanityHomePage } }) => {
  const [acLoading, setACLoading] = useState(false);
  const acFormRef = useRef(null);
  const [acForm, setACForm] = useState({});
  const [acFormErrors, setACFormErrors] = useState([]);
  const [acFormIsSuccessful, setACFormIsSuccessful] = useState(false);

  const _subscribeToActiveCampaign = async (e) => {
    e.preventDefault();
    setACFormErrors([]);
    setACLoading(true);
    setACFormIsSuccessful(false);

    let _fetch = await fetch(
      "/.netlify/functions/subscribe-to-active-campaign",
      {
        method: "POST",
        body: JSON.stringify({
          name: acForm.name,
          email: acForm.email,
        }),
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
      }
    );

    if (_fetch.ok) {
      let data = await _fetch.json();
      Array.from(e.target.elements).forEach(function (el) {
        el.value = "";
      });
      setACFormIsSuccessful(true);

      setTimeout(() => {
        setACFormIsSuccessful(false);
      }, 3000);
    } else {
      let data = await _fetch.json();
      if (_fetch.status === 422) {
        setACFormErrors(data.errors);
      }
    }

    setACLoading(false);
  };

  const heroTitleArr = sanityHomePage.heroSection.title?.split(" ");
  const heroTitleLastWord = heroTitleArr?.pop();
  const heroTitle = heroTitleArr?.join(" ");
  return (
    <Fragment>
      <Layout isHome={true}>
        <section
          className={
            "bg-gradient-to-l from-[#686C71] via-[#1F2227] to-[#000000]"
          }
        >
          <div
            className={
              "md:bg-[url(../images/home-hero-ribbon.svg)] bg-no-repeat bg-[bottom_10%_left_0px] bg-[length:120px]"
            }
          >
            <div className={"grid grid-cols-4 gap-4 container"}>
              <div
                className={
                  "col-span-4 md:col-span-2 py-10 text-white flex items-center"
                }
              >
                <div
                  // onSubmit={_subscribeToActiveCampaign}
                  ref={acFormRef}
                  id={"heroACForm"}
                >
                  <h1 className={"uppercase text-[44px] font-lora font-bold"}>
                    {heroTitle}{" "}
                    <span className={"text-yellow-200"}>
                      {heroTitleLastWord}
                    </span>
                  </h1>
                  <div>
                    <p className={"text-xl font-lora"}>
                      {sanityHomePage.heroSection.caption}
                    </p>
                    <p className={"text-sm font-roboto mt-5 mb-3"}>
                      Sign up for my Newsletter here
                    </p>
                    <div className="_form_118"></div>

                    <Script
                      src="https://lessnoisemorevolume.activehosted.com/f/embed.php?id=118"
                      type="text/javascript"
                      charSet="utf-8"
                    ></Script>
                    {/* <div className={"grid grid-cols-5 gap-4"}>
                    <div className={"col-span-2"}>
                      <input
                        type={"text"}
                        className={
                          "border border-gray-200 bg-transparent rounded-lg py-3 px-4 w-full"
                        }
                        placeholder={"First Name"}
                        defaultValue={acForm.name}
                        onChange={(e) =>
                          setACForm({ ...acForm, name: e.target.value })
                        }
                        required
                        disabled={acLoading}
                      />
                    </div>
                    <div className={"col-span-3"}>
                      <input
                        type={"email"}
                        className={`border ${
                          acFormErrors.length > 0
                            ? "border-red-500"
                            : "border-gray-200"
                        } bg-transparent rounded-lg py-3 px-4 w-full`}
                        placeholder={"Email Address"}
                        defaultValue={acForm.email}
                        onChange={(e) =>
                          setACForm({ ...acForm, email: e.target.value })
                        }
                        required
                        disabled={acLoading}
                      />
                      <p className={"text-red-500 text-sm mt-1"}>
                        {acFormErrors.map((e) => e.title).join(", ")}
                      </p>
                    </div>
                  </div>
                  <div className={"flex items-center mt-5"}>
                    <button
                      className={
                        "transition-all duration-300 bg-gradient-to-r hover:bg-gradient-to-l from-[#F4C943] to-[#FFFF95] text-black py-3 px-10 hover:px-11  rounded-lg"
                      }
                      type={"submit"}
                      disabled={acLoading}
                    >
                      {acLoading ? "Signing up..." : "Sign Up"}
                    </button>
                    {acFormIsSuccessful && (
                      <p className={"text-green-400 ml-3 mb-0"}>
                        You have been signed up successfully.
                      </p>
                    )}
                  </div> */}
                  </div>
                </div>
              </div>
              <div
                className={
                  "col-span-4 md:col-span-2 px-10 bg-[url(../images/home-nick-hero-bg.svg)] bg-no-repeat bg-center bg-[length:150%_150%] bg-[left_70%_top_20%]  flex items-end justify-center"
                }
              >
                <GatsbyImage
                  image={
                    sanityHomePage.heroSection?.thumbnail?.asset
                      ?.gatsbyImageData
                  }
                  alt={"Nick"}
                  height={600}
                  objectFit={"contain"}
                />
              </div>
            </div>
          </div>
        </section>
        <ContributingFor
          contributionSection={sanityHomePage.contributionsSection}
        />
        <section
          className={"mt-20 bg-[#d9d9d926] rounded-[50px]"}
          data-aos="fade-in"
          data-aos-offset="150"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in"
        >
          <div
            className={
              "bg-[url(../images/home/section-about-ribbon.svg)] bg-[right_bottom_20px] bg-no-repeat bg-[length:130px] container"
            }
          >
            <div className={"grid grid-cols-5 gap-4 flex items-center"}>
              <div className={"col-span-5 md:col-span-2 -my-8"}>
                <GatsbyImage
                  image={
                    sanityHomePage.aboutSection?.image?.asset?.gatsbyImageData
                  }
                  alt={"Nick"}
                  height={450}
                />
              </div>
              <div className={"col-span-5 md:col-span-3 py-10"}>
                <div className={"max-w-xl mx-auto"}>
                  <h1
                    className={"text-[44px] font-lora"}
                    dangerouslySetInnerHTML={{
                      __html: sanityHomePage.aboutSection?.title.replace(
                        "Nick Wolny",
                        '<span class="font-bold">Nick Wolny</span>'
                      ),
                    }}
                  ></h1>
                  <p className={"font-lora text-sm"}>
                    {sanityHomePage.aboutSection?.description}
                  </p>
                  <a
                    href={
                      sanityHomePage.aboutSection?.actionButton?.url || "!#"
                    }
                    className={
                      "bg-gradient-to-r transition-all hover:bg-gradient-to-l from-[#F4C943] to-[#FFFF95] text-black py-3 px-10 hover:px-11  rounded-lg mt-5 uppercase text-sm font-roboto inline-block"
                    }
                  >
                    {sanityHomePage.aboutSection?.actionButton?.text}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className={
            "my-24 bg-[url(../images/home/section-bg.svg),_url(../images/home/section-newsletter-ribbon.svg),_url(../images/home/section-camp-ribbon.svg)] bg-no-repeat bg-[position:left_top,_left_bottom_-60px,_right_top_-60px] bg-[length:100%,120px,300px]  container"
          }
          data-aos="fade-in"
          data-aos-offset="150"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in"
        >
          <div className={"grid grid-cols-5 gap-4 flex items-center"}>
            <div
              className={"col-span-5 md:col-span-3 py-16 md:order-1 order-2"}
            >
              <div className={"max-w-xl mx-auto"}>
                <h1 className={"text-[44px] font-lora"}>
                  {sanityHomePage.newsletterSection?.title}
                </h1>
                <p className={"font-lora text-sm"}>
                  {sanityHomePage.newsletterSection?.description}
                </p>
                <button
                  className={
                    "duration-300 bg-gradient-to-r transition-all hover:bg-gradient-to-l from-[#F4C943] to-[#FFFF95] text-black py-3 px-10 hover:px-11  rounded-lg mt-5 uppercase text-sm font-roboto inline-block"
                  }
                  data-hs-overlay="#active-campaign-signup-modal"
                >
                  {sanityHomePage.newsletterSection?.actionButton?.text}
                </button>
                {/*<a*/}
                {/*	href={sanityHomePage.newsletterSection?.actionButton?.url || '!#'}*/}
                {/*	className={*/}
                {/*		"transition-all duration-300 bg-gradient-to-r transition-all hover:bg-gradient-to-l from-[#F4C943] to-[#FFFF95] text-black py-3 px-10 hover:px-11  rounded-lg mt-5 uppercase text-sm font-roboto inline-block"*/}
                {/*	}*/}
                {/*>*/}
                {/*	{sanityHomePage.newsletterSection?.actionButton?.text}*/}
                {/*</a>*/}
              </div>
            </div>
            <div
              className={"col-span-5 md:col-span-2 -my-8 md:order-2 order-1"}
            >
              <GatsbyImage
                image={
                  sanityHomePage.newsletterSection?.image?.asset
                    ?.gatsbyImageData
                }
                alt={"Nick"}
                height={450}
              />
            </div>
          </div>
        </section>
        <section
          className={
            "my-24 bg-[url(../images/home/section-bg.svg),_url(../images/home/section-camp-ribbon.svg)] bg-no-repeat bg-[position:left_top,_right_bottom_-80px] bg-[length:100%,300px] container"
          }
          data-aos="fade-in"
          data-aos-offset="150"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in"
        >
          <div className={"grid grid-cols-5 gap-4 flex items-center"}>
            <div className={"col-span-5 md:col-span-2 -my-8 "}>
              <GatsbyImage
                image={
                  sanityHomePage.campWordsmithSection?.image?.asset
                    ?.gatsbyImageData
                }
                alt={"Nick"}
                height={450}
              />
            </div>
            <div className={"col-span-5 md:col-span-3 py-16"}>
              <div className={"max-w-xl mx-auto"}>
                <h1 className={"text-[44px] font-lora"}>
                  {sanityHomePage.campWordsmithSection?.title}
                </h1>
                <p className={"font-lora text-sm"}>
                  {sanityHomePage.campWordsmithSection?.description}
                </p>
                <a
                  href={
                    sanityHomePage.campWordsmithSection?.actionButton?.url ||
                    "!#"
                  }
                  className={
                    "bg-gradient-to-r transition-all hover:bg-gradient-to-l from-[#F4C943] to-[#FFFF95] text-black py-3 px-10 hover:px-11  rounded-lg mt-5 uppercase text-sm font-roboto inline-block"
                  }
                >
                  {sanityHomePage.campWordsmithSection?.actionButton?.text}
                </a>
              </div>
            </div>
          </div>
        </section>
        <section
          className={
            "my-24 bg-[url(../images/home/section-bg.svg),_url(../images/home/section-common-ribbon.svg)] bg-no-repeat bg-[position:left_top,_left_bottom_-100px] bg-[length:100%,250px] container"
          }
          data-aos="fade-in"
          data-aos-offset="150"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in"
        >
          <div className={"grid grid-cols-5 gap-4 flex items-center flex-wrap"}>
            <div
              className={"col-span-5 md:col-span-3 py-16 md:order-1 order-2"}
            >
              <div className={"max-w-xl mx-auto"}>
                <h1 className={"text-[44px] font-lora"}>
                  {sanityHomePage.speakingSection?.title}
                </h1>
                <p className={"font-lora text-sm"}>
                  {sanityHomePage.speakingSection?.description}
                </p>
                <a
                  href={
                    sanityHomePage.speakingSection?.actionButton?.url || "!#"
                  }
                  className={
                    "bg-gradient-to-r transition-all hover:bg-gradient-to-l from-[#F4C943] to-[#FFFF95] text-black py-3 px-10 hover:px-11  rounded-lg mt-5 uppercase text-sm font-roboto inline-block"
                  }
                >
                  {sanityHomePage.speakingSection?.actionButton?.text}
                </a>
              </div>
            </div>
            <div
              className={"col-span-5 md:col-span-2 -my-8 md:order-2 order-1"}
            >
              <GatsbyImage
                image={
                  sanityHomePage.speakingSection?.image?.asset?.gatsbyImageData
                }
                alt={"Nick"}
                height={450}
              />
            </div>
          </div>
        </section>
      </Layout>
      <Footer />
    </Fragment>
  );
};

export default IndexPage;

export const Head = ({ data: { sanityHomePage } }) => (
  <SEO metadata={sanityHomePage?.pageMetadata || {}} />
);

export const query = graphql`
  query {
    sanityHomePage {
      title
      speakingSection {
        title
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        description
        actionButton {
          text
          url
        }
      }
      newsletterSection {
        title
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        description
        actionButton {
          text
          url
        }
      }
      campWordsmithSection {
        title
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        description
        actionButton {
          text
          url
        }
      }
      aboutSection {
        title
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        description
        actionButton {
          text
          url
        }
      }
      contributionsSection {
        title
        contributions {
          alt
          caption
          attribution
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
      }
      pageMetadata {
        title
        schema
        keywords
        description
        canonical
        image {
          asset {
            url
          }
        }
      }
      heroSection {
        title
        caption
        thumbnail {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
      }
    }
  }
`;
